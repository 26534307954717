<template>
  <b-container>
    <b-row cols="4">
      <b-col v-for="team in teams" :key="team.id" class="mb-3">
        <SelectCard :title="team.displayname" bgVariant="grey-700" :state="team.sites" @select-card-clicked="emitSites">
          <template #card-body>
            <b-icon-gear-fill v-if="team.shortname === 'Z'" font-scale="2"/>
            <b-icon-people-fill v-else-if="user.site.team.id === team.id" font-scale="2" variant="orange-dark"/>
            <b-icon-people-fill v-else font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import SelectCard from "@/components/lists/computerList/tasks/utility/SelectCard.vue";

export default {
  name: "TeamSelect",
  components: {
    SelectCard
  },
  methods: {
    emitSites(data) {
      this.$emit('sites-selected', data)
    }
  },
  computed: {
    ...mapGetters(['teams']),
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
</style>
