<template>
  <b-card header-bg-variant="alfa-blue" header-tag="header" body-class="pb-0">
    <template #header>
      <b-row>
        <b-col cols="10" class="pl-1">
          <h6 class="mb-0 text-bold"><span class="align-middle">{{ notification.headline }}</span></h6>
        </b-col>
        <b-col cols="2">
          <button type="button" aria-label="Close" class="close" @click="deleteNotification">×</button>
        </b-col>
      </b-row>
    </template>
    <VueMarkdownRender :source="notification.message" :class="renderClasses"/>
    <div class="text-secondary mb-2" style="font-size: 14px">Erstellt am: {{ notification.created_at | datetime }}</div>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import VueMarkdownRender from "vue-markdown-render";

export default {
  name: "Notification",
  props: ['notification'],
  components: {
    VueMarkdownRender
  },
  methods: {
    async deleteNotification() {
      let data = {
        id: this.notification.id
      }
      await putRequest('notifications/delete', data, this, 'Benachrichtigung erfolgreich entfernt.', 'Benachrichtigung konnte nicht entfernt werden.')
          .then(() => {
            this.$emit('refresh')
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    renderClasses() {
      if(this.userThemeLight) { return 'text-dark' }
      return 'text-white'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
