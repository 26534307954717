<template>
  <b-container class="site-select">
    <b-row cols="4" class="align-self-stretch">
      <b-col v-for="site in sitesList" :key="site.id" class="mb-4">
        <SelectCard v-if="site.favorite" :title="site.displayname" :state="site" class="h-100" bgVariant="grey-700"
                    bodyClass="pt-1" @select-card-clicked="setSite">
          <template #card-title>
            <b-row>
              <b-col cols="auto" class="ml-auto pb-1 pr-2">
                <b-icon-star-fill variant="orange-dark" font-scale="0.8"/>
              </b-col>
            </b-row>
            <h4 class="card-title">{{ site.displayname }}</h4>
          </template>
          <template #card-body>
            <b-icon-house-door-fill v-if="user.site.shortname === site.shortname" font-scale="2" variant="orange-dark"/>
            <b-icon-house-door-fill v-else font-scale="2"/>
          </template>
        </SelectCard>
        <SelectCard v-else :title="site.displayname" :state="site" class="h-100" bgVariant="grey-700"
                    @select-card-clicked="setSite">
          <template #card-body>
            <b-icon-house-door-fill v-if="user.site.shortname === site.shortname" font-scale="2" variant="orange-dark"/>
            <b-icon-house-door-fill v-else font-scale="2"/>
          </template>
        </SelectCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import SelectCard from "@/components/lists/computerList/tasks/utility/SelectCard.vue";

export default {
  name: "SiteSelect",
  props: ['sites'],
  components: {
    SelectCard
  },
  methods: {
    ...mapActions(['selectSite']),
    setSite(site) {
      this.selectSite(site.shortname)
      if(this.$route.query.site && this.$route.query.site !== this.siteSelected.shortname) {
        this.$router.push({name: this.$route.name, query: {site: this.siteSelected.shortname}})
        this.$bvModal.hide('selectModal')
      }
      else {
       this.$bvModal.hide('selectModal')
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['siteSelected']),
    sitesList() {
      let sites = _.filter(this.sites, 'selectable')
      _.forEach(sites, (site) => {
        site.favorite = false
        if(this.user.user_setting.site_favorites) {
          if(this.user.user_setting.site_favorites.includes(site.id)) {
           site.favorite = true
          }
        }
      })
      return sites
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/Modal";
</style>
