<template>
  <vue-typeahead-bootstrap v-model="search" size="sm" placeholder="Suche..." style="width: 250px" :data="searchResults"
                           :minMatchingChars="2" :disableSort="true" :maxMatches="30" :serializer="item => item.type"
                           :showAllResults="true" @hit="selectedValue = $event" @input="getSearch">
    <template slot="suggestion" slot-scope="{ data }">
      <div v-if="data.type === 'pc'" class="search-link">
        <div class="text-bold">{{ data.hostname }}</div>
        <div>{{ data.ipv4_address }}</div>
        <div v-if="data.hardware">{{ data.hardware.hardware_configuration.displayname }}</div>
      </div>
      <div v-else class="search-link">
        <div>
          <span class="text-bold">{{ data.zuname }}, {{ data.vorname}} </span><span v-if="data.site">({{data.site.displayname}})</span>
        </div>
        <div>{{ data.kdnr }}</div>
        <div>Klassenraum-{{ data.intklr }}</div>
      </div>
    </template>
  </vue-typeahead-bootstrap>
</template>

<script>
import _ from 'lodash';
import {getRequest} from "@/modules/requests";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
  name: "GlobalSearch",
  components: {
    VueTypeaheadBootstrap
  },
  data() {
    return {
      search: '',
      searchResults: [],
      selectedValue: null
    }
  },
  watch: {
    selectedValue() {
      if(this.selectedValue.type === 'pc') {
        let hostname = this.selectedValue.hostname
        this.$router.push({name: 'Computer', params: { hostname }, query: {id: this.selectedValue.id}})
      }
      else {
        let kdnr = this.selectedValue.kdnr
        this.$router.push({name: 'Customer', params: { kdnr }})
      }
      this.search = ''
    }
  },
  methods: {
    getSearch: _.debounce(function () {
      this.$emit('search')
      let data = this.search
      if(data.length > 0) {
      getRequest('search/' + data, null, this)
          .then((response) => {
            this.$emit('search')
            this.searchResults = response.data
          })
      }
      else {
        this.$emit('search')
      }
    }, 500)
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/GlobalSearch.scss';
</style>
