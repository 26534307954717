<template>
  <b-modal id="selectModal" title="Standortauswahl" ok-only ok-title="Schließen" centered size="xl" @hide="reset">
    <div style="max-height: 45em">
      <b-row class="mb-3">
        <b-col cols="3" class="ml-3">
          <b-form-input v-model="search" debounce="500" placeholder="Suche nach Standort..."/>
        </b-col>
        <b-col cols="auto" class="ml-auto mr-3 mt-2">
          <b-icon-star-fill v-if="favoriteIcon" id="tt-favoritesHide" variant="orange-dark" class="clickable"
                            font-scale="1.2" @click="switchFavorites"/>
          <b-icon-star v-else id="tt-favoritesShow" class="clickable" font-scale="1.2" @click="switchFavorites"/>
          <b-tooltip v-if="favoriteIcon" target="tt-favoritesHide" triggers="hover">Favoriten verbergen</b-tooltip>
          <b-tooltip v-else target="tt-favoritesShow" triggers="hover">Favoriten anzeigen</b-tooltip>
        </b-col>
      </b-row>
      <SiteOverview v-if="search" :sites="searchSites"/>
      <SiteOverview v-else-if="favoriteIcon" :sites="favoriteSites"/>
      <TeamOverview v-else-if="teamSelect" @sites-selected="setSites"/>
      <SiteOverview v-else :sites="teamSites"/>
    </div>
    <template #modal-footer>
      <div class="mx-auto">
        <b-icon-arrow-left-circle v-if="!teamSelect" font-scale="2" class="mr-2 clickable" @click="stepBack"/>
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import TeamOverview from "@/components/structure/siteSelectModal/TeamOverview.vue";
import SiteOverview from "@/components/structure/siteSelectModal/SiteOverview.vue";

export default {
  name: "SiteSelectModal",
  components: {
    SiteOverview,
    TeamOverview
  },
  data() {
    return {
      search: null,
      teamSites: null,
      teamSelect: true,
      favoriteIcon: false
    }
  },
  methods: {
    reset() {
      this.search = null
      this.teamSites = null
      this.teamSelect = true
      this.favoriteIcon = false
    },
    stepBack() {
      this.teamSelect = !this.teamSelect
    },
    setSites(data) {
      this.teamSelect = !this.teamSelect
      this.teamSites = data;
    },
    switchFavorites() {
      this.favoriteIcon = !this.favoriteIcon
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['sites']),
    searchSites() {
      return _.filter(this.sites, (entry) => {
        if(entry.displayname.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    },
    favoriteSites() {
      if(this.user.user_setting.site_favorites) {
        let favoriteList = []
        _.forEach(this.user.user_setting.site_favorites, (siteId) => {
          favoriteList.push(_.find(this.sites, (site) => {
            if(site.id === siteId) {
              return true
            }
          }))
        })
        return favoriteList
      }
      else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
