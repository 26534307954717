<template>
  <div>
    <span class="mr-2" @click="openModal">{{ teamSelected.displayname }} - {{ siteSelected.displayname }}</span>
    <b-icon-house-fill @click="setDefaultSite" class="header-icon"/>
    <SiteSelectModal/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SiteSelectModal from "@/components/structure/siteSelectModal/SiteSelectModal";

export default {
  name: "SiteSelect",
  components: {
    SiteSelectModal
  },
  methods: {
    ...mapActions(['selectSite']),
    openModal() {
      this.$bvModal.show('selectModal');
    },
    setDefaultSite() {
      this.selectSite(this.user.site.shortname)
      if(this.$route.query.site && this.$route.query.site !== this.siteSelected.shortname) {
        this.$router.push({name: this.$route.name, query: {site: this.siteSelected.shortname}})
      }
    }
  },
  computed: {
    ...mapGetters(['siteSelected']),
    ...mapGetters(['teamSelected']),
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Header.scss';
</style>
