<template>
  <div>
    <b-navbar toggleable="md" :class="'header header-h ' + env" fixed="top">
      <b-navbar-brand class="header-c" :to="{name: 'Home'}">alfaSMS</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"/>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="user">
          <b-nav-item-dropdown v-if="user.roles.includes('user') && siteSelected" text="Computer" right>
            <template>
              <b-dropdown-item :to="{name: 'ComputerList', query: {site: siteSelected.shortname}}">Computerliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'RzpcList'}">RZPC-Liste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'GroupList', query: {site: siteSelected.shortname}}">Gruppenweise</b-dropdown-item>
              <b-dropdown-item :to="{name: 'VirusList', query: {site: siteSelected.shortname}}">Virenfunde</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ProcessList', query: {site: siteSelected.shortname}}">Prozessfunde</b-dropdown-item>
              <b-dropdown-item :to="{name: 'TaskList', query: {site: siteSelected.shortname}}">Aktionshistorie</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('user') && siteSelected" text="Software" right>
            <template>
              <b-dropdown-item :to="{name: 'Licenses', query: {site: siteSelected.shortname}}">Lizenzen</b-dropdown-item>
              <b-dropdown-item :to="{name: 'Checklist'}">Checkliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'SoftwareList'}">Softwareliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'TemplateList'}">Templateliste</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('user')" text="Schulung" right>
            <template>
              <b-dropdown-item :to="{name: 'News'}">News</b-dropdown-item>
              <b-dropdown-item :to="{name: 'VPN'}">VPN</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ShippingList'}">Versandliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'CustomerList'}">Kundenliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ExamChecklist'}">Prüfungscheckliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ConsultingChecklist'}">Alternative Kostenträger</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('user')" text="Sonstiges" right>
            <template>
              <b-dropdown-item :to="{name: 'SiteList'}">Standortliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ServiceList'}">Dienstübersicht</b-dropdown-item>
              <b-dropdown-item disabled>Inventur</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('consulting') || user.roles.includes('superadmin')"
                               text="Beratung" right>
            <template>
              <b-dropdown-item :to="{name: 'FGConsultingChecklist'}">Alternative Kostenträger</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('fg-exam') || user.roles.includes('superadmin')"
                               text="FG-Prüfungen" right>
            <template>
              <b-dropdown-item :to="{name: 'FGExamChecklist'}">Prüfungscheckliste</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('fg-software') || user.roles.includes('superadmin')"
                               text="FG-Software" right>
            <template>
              <b-dropdown-item :to="{name: 'FGContracts'}">Verträge</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGSoftware'}">Software</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGLicenses'}">Lizenzen</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGTemplates'}">Templates</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGChecklist'}">Checkliste</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGWebPortals'}">Webportale</b-dropdown-item>
              <b-dropdown-item :to="{name: 'FGCourseData'}">Kursdaten</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('fg-software') || user.roles.includes('superadmin')"
                               text="Admin" right>
            <template>
              <b-dropdown-item :to="{name: 'AdminNews'}">News</b-dropdown-item>
              <b-dropdown-item :to="{name: 'AdminImages'}">Images</b-dropdown-item>
              <b-dropdown-item :to="{name: 'AdminHardware'}">Hardwareliste</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="user.roles.includes('superadmin')" text="Superadmin" right>
            <template>
              <b-dropdown-item :to="{name: 'AdminUserManagement'}">Benutzerverwaltung</b-dropdown-item>
              <b-dropdown-item :to="{name: 'AdminFeedback'}">Feedback</b-dropdown-item>
              <b-dropdown-item disabled>Standorte</b-dropdown-item>
              <b-dropdown-item disabled>Service- und Updaterversions</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-form @submit.stop.prevent="">
            <b-icon-search v-if="!globalSearch" variant="white" scale="1.1" class="mr-2"/>
            <b-spinner v-else variant="white" small class="mr-2"/>
            <GlobalSearch @search="changeSearchState"/>
          </b-nav-form>
          <b-nav-item v-if="siteSelected">
            <SiteSelect class="mr-2"/>
          </b-nav-item>
          <b-nav-item>
            <div class="notification-icon">
              <b-icon-bell-fill variant="white" class="header-icon" aria-controls="notificationSidebar"
                                :aria-expanded="sidebarShow" @click="changeSidebarState"/>
              <span v-if="notification" class="notification-icon-counter">{{ notification }}</span>
            </div>
          </b-nav-item>
          <b-nav-item :to="{name: 'News'}">
            <b-icon-newspaper variant="white" class="header-icon"/>
          </b-nav-item>
          <b-nav-item href="https://doku.alfanetz.de/de/home/Schulung/Systeme/sms/index" target="_blank">
            <b-icon-question-circle-fill variant="white" class="header-icon"/>
          </b-nav-item>
          <b-nav-item-dropdown no-caret right>
            <template #button-content>
              <b-avatar variant="dark" size="2em" id="tt-avatar"/>
              <b-tooltip :target="'tt-avatar'" triggers="hover"/>
            </template>
            <div>
              <b-dropdown-item :to="{name: 'UserProfile'}">
                <li class="text-bold">Benutzerprofil</li>
              </b-dropdown-item>
              <b-dropdown-item :to="{name: 'UserSettings'}">
                <li class="text-bold">Benutzereinstellungen</li>
              </b-dropdown-item>
              <b-dropdown-item>
                <li @click="logout" class="text-danger text-bold">Abmelden</li>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item v-if="user" disabled>
                Angemeldet als {{ user.username }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <NotificationSidebar :show="sidebarShow" @all-read="getNotificationCount" @close="changeSidebarState"/>
  </div>
</template>

<script>
import {updateTitle} from "@/router";
import {getRequest} from "@/modules/requests";
import {mapActions, mapGetters} from "vuex";
import SiteSelect from "@/components/sites/SiteSelect";
import GlobalSearch from "@/components/globalSearch/GlobalSearch";
import NotificationSidebar from "@/components/sidebar/NotificationSidebar";

export default {
  name: "Header",
  components: {
    SiteSelect,
    GlobalSearch,
    NotificationSidebar
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      sidebarShow: false,
      globalSearch: false,
      notificationCount: null,
      notificationCountTimer: null
    }
  },
  methods: {
    ...mapActions(['signOut']),
    changeSidebarState() {
      this.sidebarShow = !this.sidebarShow
    },
    changeSearchState() {
      this.globalSearch = !this.globalSearch
    },
    async getNotificationCount() {
      await getRequest('notifications/unread-count', null, this, 'Es ist ein Fehler beim Aktualisieren der Daten aufgetreten.')
          .then((response) => {
            this.notificationCount = response.data;
            updateTitle(this.$route, this.$route.meta.title, this.notificationCount.count)
          })
    },
    async logout() {
      await this.signOut()
      await this.$router.replace('/login')
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['siteSelected']),
    notification() {
      if(this.notificationCount) {
        if(this.notificationCount.count > 0 && this.notificationCount.count < 10) {
          return this.notificationCount.count
        }
        else if(this.notificationCount.count > 9) {
          return '9+'
        }
        else {
          return null
        }
      }
      return null
    }
  },
  mounted() {
    this.getNotificationCount()
    this.notificationCountTimer = setInterval(() => {
      this.getNotificationCount()
    }, 180000)
  },
  beforeDestroy() {
    clearInterval(this.notificationCountTimer)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Header.scss';
</style>
